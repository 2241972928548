import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Container, Typography } from '@mui/material';
import MapView from './MapView';

function HomePage() {
    const [stateData, setStateData] = useState([]);

    useEffect(() => {
        axios.get('${process.env.REACT_APP_API_URL}/scamreports/aggregated/')
            .then(response => setStateData(response.data.state_data))
            .catch(error => console.error('Error fetching state data:', error));
    }, [stateData]);

    return (
        <div className="header-section">
        <Container>
            <div style={{ marginTop: '100px' }}></div>
            <div style={{ marginTop: '20px' }}>
                {/* <Typography variant="h6">Aggregated Scam Reports Choropleth Map</Typography> */}
                <MapView initialStateData={stateData} />
            </div>
        </Container>
        </div>
    );
}

export default HomePage;