// src/pages/MapView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapContainer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MapView.css';
// Import your DonutChart component if you have one
import DonutChart from './DonutChart';
// import ColorScaleBar from './ColorScaleBar';
import StackedBarChart from './stackedBarChart';
import YearlyLineChart from './YearlyLineChart';



function ColorScaleBar({ colorScale, min, max }) {
  const map = useMap();

  useEffect(() => {
    const colorScaleControl = L.control({ position: 'bottomright' });

    colorScaleControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'info color-scale-bar');
      const grades = [min, (min + max) / 2, max];
      const gradient = `linear-gradient(to top, ${grades.map(g => colorScale(g)).join(', ')})`;

      div.innerHTML = '<strong>Reports</strong><br>';
      div.innerHTML += `
          <div style="display: flex; align-items: center; height: 500px;">
            <!-- Gradient Bar -->
            <div style="height: 100%; width: 20px; background: ${gradient};"></div>
            
            <!-- Labels -->
            <div style="display: flex; flex-direction: column; justify-content: space-between; width: 50px; height: 100%; font-size: 12px; margin-left: 5px;">
              <div>${Math.round(max)}</div>
              <div>${Math.round((min + max) / 2)}</div>
              <div>${Math.round(min)}</div>
            </div>
          </div>
        `;
      return div;
    };

    colorScaleControl.addTo(map);

    return () => {
      colorScaleControl.remove();
    };
  }, [map, colorScale, min, max]);

  return null;
}

function MapView({ initialStateData }) {
  const [geoJSONData, setGeoJSONData] = useState(null);
  const [stateData, setStateData] = useState(initialStateData);
  const [ageGroups, setAgeGroups] = useState([]);
  const [years, setYears] = useState([]);
  const [contactModes, setContactModes] = useState([]);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedContactMode, setSelectedContactMode] = useState('');
  const [colorScaleMin, setColorScaleMin] = useState(0);
  const [colorScaleMax, setColorScaleMax] = useState(1000);

  const [selectedStateCode, setSelectedStateCode] = useState(null);
  const [selectedStateName, setSelectedStateName] = useState(null);

  useEffect(() => {
    fetch('/data/australia_states.geojson')
      .then(response => response.json())
      .then(data => setGeoJSONData(data))
      .catch(error => console.error('Error fetching GeoJSON data:', error));

    axios.get(`${process.env.REACT_APP_API_URL}/scamreports/filter-options/`)
      .then(response => {
        setAgeGroups(response.data.ageGroups);
        setYears(response.data.years);
        setContactModes(response.data.contactModes);
      })
      .catch(error => console.error('Error fetching filter options:', error));
  }, []);

  useEffect(() => {
    fetchStateData();
  }, [selectedAgeGroup, selectedYear, selectedContactMode]);
  
  const fetchStateData = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/scamreports/aggregated/`, {
      params: {
        ageGroup: selectedAgeGroup,
        year: selectedYear,
        contactMode: selectedContactMode
      }
    })
    .then(response => {
      const stateData = response.data.state_data;
      const transformedData = stateData.state.map((state, index) => ({
        state_code: stateData.state_code[index],
        state: state,
        report_count: stateData.report_count[index],
        total_amount_lost: stateData.total_amount_lost[index]
      }));

      setStateData(transformedData);
      // setShowStackedBarChart(false);
      setColorScaleMin(response.data.min_reports);
      setColorScaleMax(response.data.max_reports);
    })
    .catch(error => console.error('Error fetching state data:', error));
  };

  const getColor = (reports) => {
    return reports > colorScaleMax * 0.8 ? '#003f5c' :
           reports > colorScaleMax * 0.6 ? '#2f4b7c' :
           reports > colorScaleMax * 0.4 ? '#465a7e' :
           reports > colorScaleMax * 0.2 ? '#6c8bb2' :
                                           '#d0e3f1';
  };

  const getStyle = (feature) => {
    const stateCode = feature.properties.ste_iso3166_code;
    const stateInfo = stateData.find(state => state.state_code === stateCode);
    const reportCount = stateInfo ? stateInfo.report_count : 0;

    return {
      fillColor: getColor(reportCount),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    };
  };

  const onEachFeature = (feature, layer) => {
    const stateCode = feature.properties.ste_iso3166_code;
    const stateInfo = stateData.find(state => state.state_code === stateCode);
    const reportCount = stateInfo ? stateInfo.report_count : 'N/A';
    const amountLost = stateInfo ? stateInfo.total_amount_lost : 'N/A';
    const stateName = stateInfo ? stateInfo.state : 'N/A';

    layer.bindPopup(`
      <b>${stateName}</b><br>
      Reports: ${reportCount}<br>
      Amount Lost: ${amountLost}
    `);

    layer.on({
      mouseover: () => {
        layer.openPopup();
        layer.setStyle({
          weight: 3,
          color: '#666',
          dashArray: '',
          fillOpacity: 0.9
        });
      },
      mouseout: () => {
        layer.closePopup();
        layer.setStyle({
          weight: 2,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.7
        });
      },
      click: () => {
        setSelectedStateCode(stateCode);
        setSelectedStateName(stateName);
        
      }
    });
  };

  const handleApplyFilter = () => {
    fetchStateData();
    
  };

  const handleClearFilter = () => {
    setSelectedAgeGroup('');
    setSelectedYear('');
    setSelectedContactMode('');
    // Use a callback to ensure fetchStateData is called after state has been reset
    setStateData([]);
    setTimeout(fetchStateData, 0);
    setShowStackedBarChart(false);
    setSelectedStateName('');
  };
  const [showStackedBarChart, setShowStackedBarChart] = useState(false);

  const handleShowMoreInfo = () => {
    setShowStackedBarChart(prev => !prev);
  };
  return (
    <div className="container">
      <div className="intro-text">
        <h2>Employment Scams Across Australia</h2>
        <p>Employment scams have been a growing concern across Australia. Our data reveals significant trends in the number of reported scams across various states. Below, you can explore the distribution of these scams on the map and delve deeper into specific state-level insights.</p>
        <p>Select a state from the map to proceed and discover detailed information about scams, including demographic insights such as gender distribution and more.</p>
      </div>

      <div className="filter-section">
        <div className="filter-group">
          <label>Age Group:</label>
          <select value={selectedAgeGroup} onChange={(e) => setSelectedAgeGroup(e.target.value)}>
            <option value="">All</option>
            {ageGroups.map(age => (
              <option key={age} value={age}>{age}</option>
            ))}
          </select>
        </div>
        <div className="filter-group">
          <label>Year:</label>
          <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
            <option value="">All</option>
            {years.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="filter-group">
          <label>Contact Mode:</label>
          <select value={selectedContactMode} onChange={(e) => setSelectedContactMode(e.target.value)}>
            <option value="">All</option>
            {contactModes.map(mode => (
              <option key={mode} value={mode}>{mode}</option>
            ))}
          </select>
        </div>
        <div className="button-group">
          <button onClick={handleApplyFilter}>Apply Filter</button>
          <button onClick={handleClearFilter}>Clear Filter</button>
          
        </div>
      </div>
      <MapContainer
        center={[-25.2744, 133.7751]}
        zoom={4.4}
        style={{ height: "600px", width: "100%", backgroundColor: "white" }}
        scrollWheelZoom={false}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        zoomControl={false}
      >
        {geoJSONData && Array.isArray(stateData) && stateData.length > 0 && (
          <GeoJSON
            key={JSON.stringify(stateData)}  // Ensure the component re-renders when stateData changes
            data={geoJSONData}
            style={getStyle}
            onEachFeature={onEachFeature}
          />
        )}
       
      <ColorScaleBar colorScale={getColor} min={colorScaleMin} max={colorScaleMax} />
      
      </MapContainer>
        
      {/* Add a new container for selected state details below the map */}
    {selectedStateName && (
      <div className="selected-state-info" style={{ backgroundColor: "white", padding: "20px", marginTop: "20px" }}>
        <div className="container">
        <h3>Selected State: {selectedStateName}</h3>
        
          <DonutChart 
            state={selectedStateName}
            ageGroup={selectedAgeGroup}
            year={selectedYear}
            contactMode={selectedContactMode} 
          />
          <button className="info-button" onClick={handleShowMoreInfo}>
            {showStackedBarChart ? 'Show Less Information' : 'Show More Information'}
          </button>
        
          </div>
        {/* Conditionally render the stacked bar chart */}
        
        {showStackedBarChart && (
          <>
            <StackedBarChart
              state={selectedStateName}
              ageGroup={selectedAgeGroup}
              year={selectedYear}
              contactMode={selectedContactMode}
            />
            <YearlyLineChart
              state={selectedStateName}
              ageGroup={selectedAgeGroup}
              year={selectedYear}
              contactMode={selectedContactMode}
            />
          </>
        )}
        
      </div>
      )}
    </div>
  );
}

export default MapView;
