import React, { useState } from 'react';
import FlipCard from '../components/FlipCard';
import Quiz from '../components/Quiz';
import './Trivia.css';

const Trivia = () => {
  const [view, setView] = useState('flipcard');
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const questions = [
    { 
      title: 'The Bait and Switch', 
      front: 'You’ve got an interview for a dream job! 🎉 \n\n(Click to reveal more)' , 
      back: 'But wait… it’s actually for a position you didn’t apply for! This classic scam lures you in with a shiny title, only to switch it up once you’re in the door. Remember, if it feels fishy, it probably is! 🐟' 
    },
    { 
      title: 'The “Career Consultant”', 
      front: 'Congratulations! You’ve been ‘selected’ by a career consultant! 💼\n\n(Click to reveal more)', 
      back: 'But hold your horses! They’re just trying to sell you resume services. If they ask for money upfront, it’s time to run faster than your last job interview! 🏃‍♂️💨' 
    },
    { 
      title: 'The Direct Deposit Trap', 
      front: 'High pay for minimal work? Sounds too good to be true! 💰\n\n(Click to reveal more)', 
      back: 'If they ask for your bank info to set up direct deposit before you even meet, it’s a scam! Unless you want to fund their vacation instead of your bank account! 🌴✈️' 
    },
    { 
      title: 'The Shipping Scam', 
      front: 'Work from home and get paid to repackage items? What a deal! 📦 \n\n(Click to reveal more)', 
      back: 'Spoiler alert: you’re just a pawn in a postal fraud game! You’ll end up with stolen goods and a hefty bill. Unless you want to be a ‘professional package sender,’ steer clear! 🚫📬' 
    },
    { 
      title: 'The Fake Job Posting', 
      front: 'Job offers that sound too good to be true? 🎊 \n\n(Click to reveal more)', 
      back: 'If the salary is sky-high and the requirements are non-existent, it’s probably a scam. Remember, if they’re offering you the moon, they might just be trying to sell you a rocket! 🚀' 
    },
    { 
      title: 'The Unsolicited Offer', 
      front: 'You didn’t apply, but they found your resume! 📄\n\n(Click to reveal more)', 
      back: 'If they’re cold-calling you with a job offer, ask yourself: why are they so desperate? Unless you’re a unicorn, it’s likely a scam! 🦄' 
    },
    { 
      title: 'The Upfront Payment', 
      front: 'Pay to play? They want you to buy equipment first! 💻\n\n(Click to reveal more)', 
      back: 'If they ask you to purchase fancy software or equipment to ‘start’ your job, it’s a scam! Unless you’re auditioning for a tech infomercial, don’t fall for it! 📺' 
    },
    { 
      title: 'The Grammatical Nightmare', 
      front: 'Job description full of typos? 🤔\n\n(Click to reveal more)', 
      back: 'If it looks like it was written by a toddler with a typewriter, it’s probably a scam! Legit companies hire professionals, not grammar gremlins! 🧙‍♂️' 
    },
    { 
      title: 'The Impersonation Game', 
      front: 'Someone claims to be from a reputable company! 📞\n\n(Click to reveal more)', 
      back: 'If they’re using a personal email or asking for your info over social media, it’s a scam! Legit companies don’t slide into your DMs for job offers! 📩' 
    },
    { 
      title: 'The Employment Contract Red Flag', 
      front: 'You received a job offer without an interview! 🎊\n\n(Click to reveal more)', 
      back: 'If they send you an employment contract without even meeting you, it’s a scam! Unless you’re a secret agent, real jobs require a little more vetting! 🕵️‍♂️' 
    },
  ];

  const handleNext = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % questions.length);
    setIsFlipped(false); 
  };

  const handlePrevious = () => {
    setCurrentCardIndex((prevIndex) =>
      prevIndex === 0 ? questions.length - 1 : prevIndex - 1
    );
    setIsFlipped(false); 
  };

  return (
    <div>
      {/* Header Section Outside the Main Content */}
      <div className="header-section">
        <h1>Job Scam Shield: Protect Your Career</h1>
        <h4>Boost Your Scam Awareness </h4>
        <h7>
          Welcome to CareerTrustShield's Job Scam Awareness Hub!
          In today's digital job market, staying informed is your best defense. 
          Explore our interactive tools to sharpen your scam-spotting skills.
        </h7>
        <h4>Why Job Scam Awareness Matters</h4>
        <h7>
          <b>Protect Your Information: </b>Safeguard your personal and financial details from scammers.<br></br>
          <b>Save Time:</b> Avoid wasting time on fraudulent job postings that lead nowhere.<br></br>
          <b>Build Confidence: </b>Knowing what to look for helps you navigate the job market more effectively.<br></br>
          <b>Spread Awareness: </b>Share your knowledge with friends and family to help them stay safe.<br></br><br></br>
          <b>Did You Know?</b><br></br>
          In 2023, job scams accounted for over $200 million in losses worldwide. Staying informed is crucial for protecting yourself and others!
        </h7>
      </div>

      {/* Main Content Wrapper */}
      <div className="trivia-wrapper">
        <div className="content-area">
          <div className="button-group">
            <button 
              className={`sidebar-button ${view === 'flipcard' ? 'active' : ''}`}
              onClick={() => setView('flipcard')}
            >
              FlipCard
            </button>
            <button 
              className={`sidebar-button ${view === 'quiz' ? 'active' : ''}`}
              onClick={() => setView('quiz')}
            >
              Test Your Knowledge
            </button>
          </div>

          {/* Dynamic Text Based on Selected View */}
          <div className="header-section">
            {view === 'flipcard' ? (
              <h7>
                Are you aware of the red flags that indicate a job scam? <br></br><br></br>Our flip cards provide essential information to help you recognize common scams and protect yourself in the job market. Each card features a key concept or warning sign associated with job scams. Simply click to flip the card and reveal important details that can keep you safe.
              </h7>
            ) : (
              <h7>
                Think You Can Spot a Job Scam?<br></br><br></br> Put your knowledge to the test with our trivia quiz! Answer the questions and see how well you understand job scams.
              </h7>
            )}
          </div>

          {view === 'flipcard' ? (
            <div className="trivia-card">
              <h2>{questions[currentCardIndex].title}</h2>
              <FlipCard 
                frontText={questions[currentCardIndex].front} 
                backText={questions[currentCardIndex].back} 
                isFlipped={isFlipped} 
                setIsFlipped={setIsFlipped} 
              />
              <div className="button-group">
                <button className="prev-button" onClick={handlePrevious}>Previous</button>
                <button className="next-button" onClick={handleNext}>Next</button>
              </div>
            </div>
          ) : (
            <Quiz /> 
          )}
        </div>
      </div>
    </div>
  );
};

export default Trivia;
