import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const StackedBarChart = ({ state, ageGroup, year, contactMode }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contactModes, setContactModes] = useState([]);

  useEffect(() => {
    if (state) {
      fetchData(state, ageGroup, year, contactMode);
    }
  }, [state, ageGroup, year, contactMode]);

  const fetchData = async (state, ageGroup, year, contactMode) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/scamreports/stacked_bar_chart/`, {
        params: {
          state,
          year,
          contactMode
        }
      });
      const data = response.data.chart_data;
      setChartData(data);
      
      // Extract contact modes from the data keys
      if (data.length > 0) {
        const keys = Object.keys(data[0]).filter(key => key.startsWith('contact_mode_'));
        setContactModes(keys);
      }
    } catch (error) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  // Custom Tooltip content
  const CustomTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ margin: 0 }}>
              {entry.name.replace('contact_mode_', '')}: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  // Custom Legend content
  const CustomLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '20px' }}>
        {payload.map((entry, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <div style={{ width: '12px', height: '12px', backgroundColor: entry.color, marginRight: '10px' }}></div>
            <span>{entry.value.replace('contact_mode_', '')}</span>
          </div>
        ))}
      </div>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (chartData.length === 0) return <div>No data available</div>;

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <ResponsiveContainer width="70%" height={600}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="age_group" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {/* <div style={{ width: '30%' }}> */}
          <Legend content={<CustomLegend />} verticalAlign="top" align="right" />
          {/* </div> */}
          
          {contactModes.map((mode, index) => (
            <Bar
              key={mode}
              dataKey={mode}
              stackId="a"
              fill={['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57'][index % 5]} // Adjust colors as needed
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      
    </div>
  );
};

export default StackedBarChart;
