import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const YearlyLineChart = ({ state, ageGroup, year, contactMode }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (state) {
      fetchChartData(state, ageGroup, year, contactMode);
    }
  }, [state, ageGroup, year, contactMode]);

  const fetchChartData = async (state, ageGroup, year, contactMode) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/scamreports/yearly_line_chart/`, {
        params: {
          state,
          ageGroup,
          year,
          contactMode,
        },
      });
      setChartData(response.data.yearly_data);
    } catch (error) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div></div>;
  if (error) return <div>{error}</div>;
  if (chartData.length === 0) return <div>No data available</div>;

  return (
    <ResponsiveContainer width="80%" height={600}>
        <LineChart data={chartData}
        margin={{ top: 50, right: 80, left: 50, bottom: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            
            {/* Primary y-axis for total reports */}
            <YAxis 
            yAxisId="left" 
            label={{ value: 'Total Reports', angle: -90, position: 'insideLeft' }} 
            domain={['auto', 'auto']}  // Dynamically adjust based on data
            />
            
            {/* Secondary y-axis for total amount lost */}
            <YAxis
                yAxisId="right"
                orientation="right"
                scale="linear"
                label={{ value: 'Total Amount Lost (AU$)', angle: 90, position: 'insideRight', dx: 50, style: { textAnchor: 'middle' } }}
                domain={[0, 'dataMax + 10000000']}
                tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)}
                allowDataOverflow={false}
                // reversed={true} // Add this line
                />
            
            <Tooltip />
            <Legend />
            
            {/* Line for total reports */}
            <Line
            yAxisId="left"
            type="monotone"
            dataKey="total_reports"
            stroke="#253494"
            activeDot={{ r: 8 }}
            name="Total Reports"
            allowDataOverflow={false}
            />
            
            {/* Line for total amount lost */}
            <Line
            yAxisId="right"
            type="monotone"
            dataKey="total_amount_lost"
            stroke="#ae017e"
            name="Total Amount Lost"
            allowDataOverflow={false}
            />
        </LineChart>
        </ResponsiveContainer>

  );
};

export default YearlyLineChart;
