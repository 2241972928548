import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import PasswordProtect from './PasswordProtect';
import Trivia from './pages/Trivia';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop'; 
import Dashboard from './components/Dashboard';
import Chatbot from './components/ChatBot';
import PastScams from './pages/PastScams';


function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is already authenticated by looking for the token in localStorage
    const token = localStorage.getItem('access_token');
    if (token) {
      setAuthenticated(true);
    }
  }, []);

  const handleAuthentication = (auth) => {
    if (auth) {
      localStorage.setItem('access_token', 'authenticated');
      setAuthenticated(true);
    }
  };

  return (
    <Router>
      <ScrollToTop />  {/* Ensure ScrollToTop is outside of Routes */}
      {authenticated && <Navbar />}  {/* Navbar is displayed only when authenticated */}
      <Routes>
        {authenticated ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/trivia" element={<Trivia />} />
            <Route path="/pastscams" element={<PastScams />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route
              path="*"
              element={
                <PasswordProtect onAuthenticated={handleAuthentication} />
              }
            />
          </>
        )}
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
