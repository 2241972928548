import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="hero-background">
      <header className="hero-section">
        <div>
          <h1 style={{ fontSize: '3rem', color: 'white', marginTop: '-70px'}}>Empowering Vulnerable Job Seekers in Australia</h1>
          <p style={{ fontSize: '1.5rem', color: 'white' }}>Your Defense Against Fake Job Opportunities</p>
          <a href="#get-started-section" className="get-started-btn">Get Started Now</a>
        </div>
      </header>

      <div className="about-section">
        <h2>About CareerTrust Shield</h2>
        <p>
          At CareerTrust Shield, we believe that everyone deserves a fair chance at finding meaningful employment. 
          Our mission is to provide support and resources to vulnerable job seekers in Australia.
        </p>
        <p>
          Whether you are a stay-at-home parent looking to re-enter the workforce or a migrated student navigating 
          the job market, CareerTrust Shield is here to help. We offer valuable insights into scam trends and 
          promote safer job searching practices.
        </p>
        <p>
          Our team is dedicated to empowering job seekers and ensuring that they have the tools they need to succeed. 
          Join us in our mission to create a safer and more secure job searching experience.
        </p>
      </div>

      <div id="get-started-section" className="content-section">
        {/*
        <Link to="/job-posting" className="card">
          <div className="card-icon"><i className="fas fa-briefcase"></i></div>
          <hr />
          <h3>Is this job posting fake?</h3>
          <p>A tool to help you with the trustworthiness of any job posting.</p>
        </Link>
        <Link to="/malicious-links" className="card">
          <div className="card-icon"><i className="fas fa-link"></i></div>
          <hr />
          <h3>Verify Malicious Links</h3>
          <p>A service to check the safety of links before you click.</p>
        </Link>
        <Link to="/meet-amy" className="card">
          <div className="card-icon"><i className="fas fa-user"></i></div>
          <hr />
          <h3>Meet Amy, Your Career Assistant!</h3>
          <p>Your personal guide to navigating job opportunities and resources.</p>
        </Link>
        */}
        <Link to="/pastscams" className="card">
          <div className="card-icon"><i className="fas fa-history"></i></div>
          <hr />
          <h3>Explore Past Scams in Australia</h3>
          <p>A database of previously reported scams to help you stay informed.</p>
        </Link>
        {/*
        <Link to="/chrome-extension" className="card">
          <div className="card-icon"><i className="fas fa-chrome"></i></div>
          <hr />
          <h3>Check Out Our Chrome Extension!</h3>
          <p>A handy tool to enhance your job search and protect against scams.</p>
        </Link>
        */}
        <Link to="/trivia" className="card">
          <div className="card-icon"><i className="fas fa-puzzle-piece"></i></div>
          <hr />
          <h3>Stay Scam Smart!</h3>
          <p>An informative resource to increase your awareness of employment scams.</p>
        </Link>
        {/*
        <Link to="/report-scam" className="card">
          <div className="card-icon"><i className="fas fa-exclamation-triangle"></i></div>
          <hr />
          <h3>Report an Employment Scam</h3>
          <p>Step-by-step guidance on reporting suspicious job offers and scams.</p>
        </Link>
        <Link to="/job-opportunities" className="card">
          <div className="card-icon"><i className="fas fa-search"></i></div>
          <hr />
          <h3>Discover Job Opportunities</h3>
          <p>A curated list of legitimate job openings tailored to your career goals.</p>
        </Link>
        */}
      </div>
            <div className="empowerment-section">
        <h2>CareerTrust Shield empowers job seekers to navigate the digital job market safely with the following features:</h2>
        <ul>
          <li>Detects fake job postings using advanced models</li>
          <li>Identifies malicious websites to protect your personal information</li>
          <li>Offers a web browser extension for real-time protection</li>
          <li>Provides Amy, your personal chatbot assistant for job search guidance</li>
          <li>Increase scam awareness with flip cards and trivia</li>
          <li>Track scam trends through our interactive dashboard</li>
        </ul>
      </div>
    </div>
  );
};

export default HomePage;
