import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="navbar-container">
      <div className={`navbar ${isSticky ? 'fixed' : ''}`}>
        <div className="navbar-logo">
          <img src="/careertrust.png" alt="CareerTrust Shield" className="logo" />
          <h1>CareerTrust Shield</h1>
        </div>
        <div className="navbar-links">
          <div className="dropdown">
            <button onClick={() => setIsSticky(!isSticky)} className="dropdown-button">
              Our services ▼
            </button>
            <div className="dropdown-content">
              <Link to="/trivia">Stay Scam Smart</Link>
              <Link to="/pastscams">Explore Past Scams in Australia</Link>
            </div>
          </div>
          <Link to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
