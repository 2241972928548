import React from 'react';
import './FlipCard.css';

const FlipCard = ({ frontText, backText, isFlipped, setIsFlipped }) => {

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          {frontText}
        </div>
        <div className="flip-card-back">
          {backText}
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
