import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const DonutChart = ({ state, ageGroup, year, contactMode }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (state) {
      fetchData(state, ageGroup, year, contactMode);
    }
  }, [state, ageGroup, year, contactMode]);

  const fetchData = async (state, ageGroup, year, contactMode) => {
    console.log("Fetching data with filters:", { state, ageGroup, year, contactMode });
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/scamreports/donut_chart/`, {
        params: { state, ageGroup, year, contactMode },
      });
      console.log("API Response:", response.data); 
      setChartData(response.data.chart_data);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  const renderTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px', borderRadius: '5px' }}>
          <p>{`Gender: ${payload[0].payload.gender}`}</p>
          <p>{`Report Count: ${payload[0].value}`}</p>
          <p>{`Amount Lost: $${payload[0].payload.amount_lost}`}</p>
        </div>
      );
    }
    return null;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (chartData.length === 0) return <div>No data available for this state</div>;

  return (
    <div style={{ width: '100%', height: 'auto', position: 'relative', padding: '0' }}>
  <ResponsiveContainer width="100%" height={400}>
    <PieChart>
      <Pie
        data={chartData}
        cx="50%"
        cy="50%"
        labelLine={false}
        innerRadius={70}
        outerRadius={150}
        fill="#8884d8"
        dataKey="report_count"
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip content={renderTooltip} />
      <Legend
        layout="vertical"
        verticalAlign="middle"
        align="right"
        wrapperStyle={{
          position: 'absolute',
          right: 0,
          top: 0,
          width: '20%',
          height: '100%',
          padding: '0',
          margin: '0',
        }}
        payload={chartData.map((entry, index) => ({
          value: entry.gender,
          type: 'square',
          color: COLORS[index % COLORS.length]
        }))}
      />
    </PieChart>
  </ResponsiveContainer>
</div>
  );
};

export default DonutChart;
