import React from 'react';
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="acknowledgement-section">
      <div className="navbar-logo">
        <img src="/careertrust.png" alt="CareerTrust Shield" className="logo" />
        <h1>CareerTrust Shield</h1>
      </div>
      <h3>Acknowledgement</h3>
      <p>
        We acknowledge the Traditional Custodians of the lands across Australia on which we live and work.
        We acknowledge their connection to this Country and pay our respect to Elders past, present and emerging.
      </p>
    </footer>
  );
};

export default Footer;
